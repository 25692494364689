import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-zoom';
import zoomPlugin from 'chartjs-plugin-zoom';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);



const ZoomableBarChart = ({ isPopup = false, title, minIndex, maxIndex, datasets, labelData }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isZoomed, setIsZoomed] = useState(false);
    const chartRef = useRef(null);
    // console.log("min,max",minIndex,maxIndex,"labelData",labelData)
    const styles = {
        barChartContainer: {
            display: 'flex',
            flexDirection: "column",
            justifyContent: "space-between",
            background: "white",
            padding: "16px",
            borderRadius: "5px",
            boxShadow: isPopup ? "0px 0px 0px transparent" : "0px 0px 28px 0px #00000014",
            width: "100%",
            height: '100%'
        },
        header: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "16px",
            fontWeight: "bold",
            width: "100%",
            height: '20%',
            gap: '10px'
        },
        button: (disabled) => ({
            padding: "6px 6px",
            fontSize: "14px",
            fontWeight: "bold",
            backgroundColor: disabled ? "rgba(226, 11, 24, 0.4)" : "rgba(226, 11, 24, 0.7)",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: disabled ? "not-allowed" : "pointer",
            transition: "background 0.3s",
        }),
        title: {
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "30px",
            letterSpacing: "0%",
            textAlign: "left",
            width: "100%",
            color: "#000",
            padding: 0,
            margin: 0
        },
        loader: {
            textAlign: "center",
            padding: "20px",
            fontSize: "14px",
            fontWeight: "bold",
            color: "#555"
        }
    };

    useEffect(() => {
        if (labelData?.length > 0 && datasets?.length > 0) {
            setIsLoading(false);
        }
    }, [labelData, datasets]);

    const chartData = useMemo(() => {
        if (!labelData || !datasets || datasets.length === 0 || minIndex === null || maxIndex === null) return null;
    
        // Ensure indices are within bounds
        const safeMinIndex = Math.max(0, minIndex);
        const safeMaxIndex = Math.min(labelData.length - 1, maxIndex + 1); // Ensure it does not exceed array length
    
        return {
            labels: labelData.slice(safeMinIndex, safeMaxIndex + 1)?.map(date =>
                new Date(date).toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                    year: "2-digit"
                })
            ),
            datasets: datasets.map(dataset => ({
                data: dataset?.data?.slice(safeMinIndex, safeMaxIndex + 1), // Ensure slicing datasets correctly
                backgroundColor: dataset?.backgroundColor?.slice(safeMinIndex, safeMaxIndex + 1),
            }))
        };
    }, [labelData, datasets, minIndex, maxIndex]);
    

    const chartOptions = useMemo(() => ({
        responsive: true,
        plugins: {
            legend: { display: false },
            zoom: {
                pan: { enabled: true, mode: "x" },
                zoom: {
                    wheel: { enabled: true },
                    pinch: { enabled: true },
                    mode: "x",
                    limits: { x: { min: 0, max: 10 } }
                }
            }
        },
        scales: {
            x: { grid: { display: false } },
            y: { grid: { display: false } }
        }
    }), []);

    const resetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom();
            setIsZoomed(false);
        }
    };

    const increaseZoom = () => {
        if (chartRef.current) {
            chartRef.current.zoom(1.5);
            setIsZoomed(true);
        }
    };

    useEffect(() => {
        if (chartRef.current) {
            const chartInstance = chartRef.current;
            const { min, max } = chartInstance.scales.x;
            if (min === 0 && max === 10) {
                setIsZoomed(false);
            } else {
                setIsZoomed(true);
            }
        }
    }, [chartRef]);

    if (isLoading || !chartData) {
        return <div style={styles.loader}>Loading chart...</div>;
    }

    return (
        <div style={styles.barChartContainer}>
            <div style={styles.header}>
                <p style={styles.title}>{title}</p>
                {/* <button style={{ ...styles.button(isZoomed), border: '0px solid transparent' }} onClick={increaseZoom} disabled={isZoomed}>
                    <ZoomOutMapIcon sx={{ width: '18px', height: '18px' }} />
                </button>

                <button style={{ ...styles.button(!isZoomed), border: '0px solid transparent' }} onClick={resetZoom} disabled={!isZoomed}>
                    <ZoomInMapIcon sx={{ width: '18px', height: '18px' }} />
                </button> */}
            </div>

            {(!(!labelData || !datasets || datasets.length === 0)) ? (<Bar ref={chartRef} data={chartData} options={chartOptions} width={700} height={300} />) : (
                <div style={{ width: '100%', height: "100%", display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    NO DATA AVAILABLE
                </div>)}
        </div>
    );
};

export default ZoomableBarChart;
