import { Box, Button, Checkbox, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { AddButton, approval, arrowLeft, chat, eastWhiteArrow, EditIconn, engagementrate, favoriteLike, folloers, HandEye, infoCircle, instagramI, instagramIcon, instared, posts, report, SearchNormal, share, visibility, YoutubeIcon, youtubeRed, ytred } from '../../assets'
import Breadcrumb from '../../Atoms/breadcrumb/breadcrumb'
import useDeviceType from '../../utils/DeviceType';
import Apis from '../../services/apis';
import { NoDataImage, rearrange } from '../../assets/index';
import { useNavigate, useSearchParams } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import './report.css'
import CampaignInsights from '../../Molecules/insights/CampaignInsights';
import BrandInsights from '../../Molecules/insights/BrandInsights';
import dayjs from 'dayjs'
import ZoptsuUnderlineTitle from '../../Atoms/zoputsu-underline-title-text/zoptsu-underline-title';
import { Col } from 'react-bootstrap';
import ZupotsuTooltip from '../../Atoms/zupotsu-tooltip/zupotsu-tooltip';
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);


const Brandanalysis = () => {
    const deviceType = useDeviceType();
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [data, setData] = useState<any>([]);
    const [selectRange, setSelectRange] = useState<any>([])
    const [selectDate, setSelectDate] = React.useState<any>("");
    const [loader, setLoader] = useState(true);
    const [selectedPost, setSelectedPost] = useState("all")
    const [selectedPostDetail, setSelectedPostDetail] = useState<any>({})
    const [selectedIndex, setSelectedIndex] = useState<any>(0)
    const [selectedType, setSelectedType] = useState("post")
    const [selectedInsights, setSelectedInsights] = useState("Brand Insights")
    const [allPosts, setAllPosts] = useState<any>([]);
    const [allHashtags, setAllHashtags] = useState<any>([]);
    const [allbrandInsights, setAllBrandSights] = useState<any>([]);
    const [campaignData, setCampaignData] = useState<any>({})
    const [openChartPopup, setOpenChartPopup] = useState(false);
    const [chartname, setChartName] = useState("");

    const [allPostsData, setAllPostsData] = useState<any>([]);
    const [allHashtagsData, setAllHashtagsData] = useState<any>([]);
    const [allbrandInsightsData, setAllBrandSightsData] = useState<any>([]);


    const apis = new Apis();
    const linkDetails = useMemo(() => [
        {
            label: 'My Campaigns',
            url: '/campaigns',
        },
        {
            label: 'Analysis',
            url: '',
        },
    ], []);


    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectDate(event.target.value);
    };


    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'success',
        message: '',
    });

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };
    const navigate = useNavigate();

    const ErrorData = useMemo(
        () => ({
            img: NoDataImage,
            button: false,
            message:
                'No assets found',
        }),
        []
    );



    useEffect(() => {
        setLoader(true)
        const startTime = performance.now();

        const fetchAndTrack = async () => {
            await onLoad()
            // const loadTime = performance.now() - startTime;
            // mixpanelEvents.onLoad(loadTime, 'Manage Tray');
        };
        if (id) {
            fetchAndTrack();
        }
        return () => {
            // const timeSpent = performance.now() - startTime;
            // mixpanelEvents.onUnload('Manage Tray', timeSpent);
        };
    }, [
        // selectDate
    ])



    const [postDatess, setPostDates] = useState<any>([])
    const [postColors, setPostColors] = useState<any>([])
    const [postLikess, setPostlikes] = useState<any>([])
    const [postComments, setPostComments] = useState<any>([])
    const [postShares, setPostShares] = useState<any>([])
    const [postMedia, setPostMedia] = useState<any>([])
    const [postView, setPostView] = useState<any>([])
    const [postEngagement, setPostEngagement] = useState<any>([])
    const [postbasicEng, setPostbasicEng] = useState<any>([])
    const [postimpressEng, setPostimpressEng] = useState<any>([])
    const [postweightEng, setPostWeightEng] = useState<any>([])
    const [postSData, setPostsData] = useState<any>([])

    const [hashtagDates, setHashtagDates] = useState<any>([])
    const [hashtagColors, setHashtagColors] = useState<any>([])
    const [hashtagLikess, setHashtaglikes] = useState<any>([])
    const [hashtagComments, setHashtagComments] = useState<any>([])
    const [hashtagShares, setHashtagShares] = useState<any>([])
    const [hashtagMedia, setHashtagMedia] = useState<any>([])
    const [hashtagView, setHashtagView] = useState<any>([])
    const [hashtagEngagement, setHashtagEngagement] = useState<any>([])
    const [hashtagbasicEng, setHashtagbasicEng] = useState<any>([])
    const [hashtagimpressEng, setHashtagimpressEng] = useState<any>([])
    const [hashtagweightEng, setHashtagWeightEng] = useState<any>([])

    const [brandDates, setBrandDates] = useState<any>([])
    const [brandColors, setBrandColors] = useState<any>([])
    const [brandLikess, setBrandlikes] = useState<any>([])
    const [brandComments, setBrandComments] = useState<any>([])
    const [brandShares, setBrandShares] = useState<any>([])
    const [brandPosts, setBrandPosts] = useState<any>([])
    const [brandView, setBrandView] = useState<any>([])
    const [brandFollowers, setBrandFollowers] = useState<any>([])
    const [brandFollowing, setBrandFollowing] = useState<any>([])

    const [brandbasicEngagementRate, setBrandBasicEngagementRate] = useState<any>([])
    const [brandimpressionEngagementRate, setBrandimpressionEngagementRate] = useState<any>([])
    const [brandweightedEngagementRate, setBrandweightedEngagementRate] = useState<any>([])


    let fromDate: any = null;
    let toDate: any = null;
    if (data?.campaign?.post_camp_to && data?.campaign?.post_camp_from && data?.campaign?.camp_from && data?.campaign?.camp_to && data?.campaign?.pre_camp_from && data?.campaign?.pre_camp_to) {
        fromDate = data?.campaign?.pre_camp_from ? new Date(data?.campaign?.pre_camp_from) : null;
        toDate = data?.campaign?.post_camp_to ? new Date(data?.campaign?.post_camp_to) : null;
    }

    else if (data?.campaign?.post_camp_from && data?.campaign?.post_camp_to && data?.campaign?.pre_camp_from && data?.campaign?.pre_camp_to) {
        fromDate = data?.campaign?.pre_camp_from ? new Date(data?.campaign?.pre_camp_from) : null;
        toDate = data?.campaign?.post_camp_to ? new Date(data?.campaign?.post_camp_to) : null;
    }

    else if (data?.campaign?.camp_from && data?.campaign?.camp_to && data?.campaign?.pre_camp_from && data?.campaign?.pre_camp_to) {
        fromDate = data?.campaign?.pre_camp_from ? new Date(data?.campaign?.pre_camp_from) : null;
        toDate = data?.campaign?.camp_to ? new Date(data?.campaign?.camp_to) : null;
    }

    else if (data?.campaign?.post_camp_to && data?.campaign?.post_camp_from && data?.campaign?.camp_from && data?.campaign?.camp_to) {
        fromDate = data?.campaign?.camp_from ? new Date(data?.campaign?.camp_from) : null;
        toDate = data?.campaign?.post_camp_to ? new Date(data?.campaign?.post_camp_to) : null;
    }

    else if (data?.campaign?.post_camp_to && data?.campaign?.post_camp_from) {
        fromDate = data?.campaign?.post_camp_from ? new Date(data?.campaign?.post_camp_from) : null;
        toDate = data?.campaign?.post_camp_to ? new Date(data?.campaign?.post_camp_to) : null;
    }

    else if (data?.campaign?.camp_to && data?.campaign?.camp_from) {
        fromDate = data?.campaign?.camp_from ? new Date(data?.campaign?.camp_from) : null;
        toDate = data?.campaign?.camp_to ? new Date(data?.campaign?.camp_to) : null;
    }

    else if (data?.campaign?.pre_camp_to && data?.campaign?.pre_camp_from) {
        fromDate = data?.campaign?.pre_camp_from ? new Date(data?.campaign?.pre_camp_from) : null;
        toDate = data?.campaign?.pre_camp_to ? new Date(data?.campaign?.pre_camp_to) : null;
    }

    const minDate = fromDate instanceof Date && !isNaN(fromDate.getTime()) ? fromDate.toISOString().split("T")[0] : "";
    const maxDate = toDate instanceof Date && !isNaN(toDate.getTime()) ? toDate.toISOString().split("T")[0] : "";

    const minDat =
        fromDate instanceof Date && !isNaN(fromDate.getTime())
            ? dayjs(fromDate).tz("Asia/Kolkata")
            : null; // Use null instead of empty string

    const maxDat =
        toDate instanceof Date && !isNaN(toDate.getTime())
            ? dayjs(toDate).tz("Asia/Kolkata")
            : null;

    const onLoad = () => {
        setLoader(true)
        apis.getCampaignSocialStatus(id)
            .then((response: any) => {
                setLoader(true)
                const res = response
                setData(response?.data?.data)
                setCampaignData(response?.data?.data?.campaign)
                if (!response?.data?.data || !Array.isArray(response?.data?.data?.data) || response?.data?.data?.data.length === 0) {
                    setSnackbar({
                        open: true,
                        severity: 'error',
                        message: "No Data found",
                    });
                    setLoader(false);
                    return;
                }                
              
                
                const firstDate = response?.data?.data?.data[0]?.date;
                const lastDate = response?.data?.data?.data?.[response?.data?.data?.data?.length - 1]?.date;

                // const fromDate = dayjs(response?.data?.data?.campaign?.pre_camp_from).tz("Asia/Kolkata");
                // const toDate = dayjs(response?.data?.data?.campaign?.pre_camp_to).tz("Asia/Kolkata");
                // let toDtae

                let fromDate: any = null;
                let toDate: any = null;
                if (data?.campaign?.post_camp_to && data?.campaign?.post_camp_from && data?.campaign?.camp_from && data?.campaign?.camp_to && data?.campaign?.pre_camp_from && data?.campaign?.pre_camp_to) {
                    fromDate = data?.campaign?.pre_camp_from ? dayjs(data?.campaign?.pre_camp_from).tz("Asia/Kolkata") : null;
                    toDate = data?.campaign?.post_camp_to ? dayjs(data?.campaign?.post_camp_to).tz("Asia/Kolkata") : null;
                }

                else if (data?.campaign?.post_camp_from && data?.campaign?.post_camp_to && data?.campaign?.pre_camp_from && data?.campaign?.pre_camp_to) {
                    fromDate = data?.campaign?.pre_camp_from ? dayjs(data?.campaign?.pre_camp_from).tz("Asia/Kolkata") : null;
                    toDate = data?.campaign?.post_camp_to ? dayjs(data?.campaign?.post_camp_to).tz("Asia/Kolkata") : null;
                }

                else if (data?.campaign?.camp_from && data?.campaign?.camp_to && data?.campaign?.pre_camp_from && data?.campaign?.pre_camp_to) {
                    fromDate = data?.campaign?.pre_camp_from ? dayjs(data?.campaign?.pre_camp_from).tz("Asia/Kolkata") : null;
                    toDate = data?.campaign?.camp_to ? dayjs(data?.campaign?.camp_to).tz("Asia/Kolkata") : null;
                }

                else if (data?.campaign?.post_camp_to && data?.campaign?.post_camp_from && data?.campaign?.camp_from && data?.campaign?.camp_to) {
                    fromDate = data?.campaign?.camp_from ? dayjs(data?.campaign?.camp_from).tz("Asia/Kolkata") : null;
                    toDate = data?.campaign?.post_camp_to ? dayjs(data?.campaign?.post_camp_to).tz("Asia/Kolkata") : null;
                }

                else if (data?.campaign?.post_camp_to && data?.campaign?.post_camp_from) {
                    fromDate = data?.campaign?.post_camp_from ? dayjs(data?.campaign?.post_camp_from).tz("Asia/Kolkata") : null;
                    toDate = data?.campaign?.post_camp_to ? dayjs(data?.campaign?.post_camp_to).tz("Asia/Kolkata") : null;
                }

                else if (data?.campaign?.camp_to && data?.campaign?.camp_from) {
                    fromDate = data?.campaign?.camp_from ? dayjs(data?.campaign?.camp_from).tz("Asia/Kolkata") : null;
                    toDate = data?.campaign?.camp_to ? dayjs(data?.campaign?.camp_to).tz("Asia/Kolkata") : null;
                }

                else if (data?.campaign?.pre_camp_to && data?.campaign?.pre_camp_from) {
                    fromDate = data?.campaign?.pre_camp_from ? dayjs(data?.campaign?.pre_camp_from).tz("Asia/Kolkata") : null;
                    toDate = data?.campaign?.pre_camp_to ? dayjs(data?.campaign?.pre_camp_to).tz("Asia/Kolkata") : null;
                }


                console.log("fromDate:", fromDate, "Todate", toDate);
                setSelectRange([fromDate, toDate])

                var selectDate = lastDate.split("T")[0]
                console.log("selectDateselectDateselectDate", selectDate)
                if (selectDate) {
                    setSelectDate(selectDate)
                } else {
                    setSelectDate(new Date().toISOString().split("T")[0])
                }
                if (selectDate) {
                    const posts = response?.data?.data?.data
                        .filter((i: any) => i.date === selectDate)
                        ?.flatMap((item: any) => item.insights?.campaignInsights || [])
                        .filter((insight: any) => insight.campaign_type === "post") || [];
                    setAllPosts(posts || [])

                    const hashtag = response?.data?.data?.data
                        .filter((i: any) => i.date === selectDate)
                        ?.flatMap((item: any) => item.insights?.campaignInsights || [])
                        .filter((item: any) => item.campaign_type === "hashtag") || [];
                    setAllHashtags(hashtag || [])

                    const brandSights = response?.data?.data?.data
                        .filter((i: any) => i.date === selectDate)
                        ?.flatMap((item: any) => item.insights?.brandInsights || []) || [];
                    setAllBrandSights(brandSights || [])

                    if (selectedInsights == "Campaign Insights") {
                        if (selectedType == "post") {
                            // setSelectedPostDetail(posts[0])
                            setSelectedPost("all")
                        } else if (selectedType == "hashtag") {
                            setSelectedPostDetail(hashtag[0])
                            setSelectedPost("all")
                        }
                    }
                    if (selectedInsights == "Brand Insights") {
                        setSelectedPostDetail(brandSights[0])
                        setSelectedPost("all")
                    }
                }

                setLoader(false)
            })
            .catch((error: any) => {
                setLoader(false)
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: ((error?.response?.data?.error?.includes('prisma')) ? error?.response?.data?.error : (error?.response?.data?.error || 'something went wrong!!')),
                });
                // mixpanelEvents.errorHandling({
                //   name: 'Manage Tray',
                //   msg: error?.response?.data?.error || error?.message
                // })
            });
    }


    useEffect(() => {

        if (data) {
            setLoader(true)

            const isAllSelected = selectedPost === "all";

            const posts = data?.data
                ?.filter((i: any) => i.date === selectDate)
                // ?.filter((i: any) => isAllSelected || i.date === selectDate)
                ?.flatMap((item: any) => item.insights?.campaignInsights || [])
                .filter((insight: any) => insight.campaign_type === "post") || [];
            setAllPosts(posts || [])

            const hashtag = data?.data
                ?.filter((i: any) => i.date === selectDate)
                // ?.filter((i: any) => isAllSelected || i.date === selectDate)
                ?.flatMap((item: any) => item.insights?.campaignInsights || [])
                .filter((item: any) => item.campaign_type === "hashtag") || [];
            setAllHashtags(hashtag || [])

            const brandSights = data?.data
                ?.filter((i: any) => i.date === selectDate)
                // ?.filter((i: any) => isAllSelected || i.date === selectDate)
                ?.flatMap((item: any) => item.insights?.brandInsights || []) || [];
            setAllBrandSights(brandSights || [])

            if (selectedInsights == "Campaign Insights") {
                if (selectedType == "post") {
                    if (selectedPost == "all") {
                        const uniquePosts = new Map();

                        posts?.forEach((obj: any) => {
                            if (obj?.post_id && !uniquePosts.has(obj.post_id)) {
                                uniquePosts.set(obj.post_id, obj);
                            }
                        });

                        const summedObject = Array.from(uniquePosts.values()).reduce((acc: any, obj: any) => {
                            Object.keys(obj).forEach((key) => {
                                const value = obj[key];
                                if (typeof value === "number" || value === null) {
                                    acc[key] = (acc[key] || 0) + (value || 0);
                                }
                            });
                            return acc;
                        }, {});

                        setSelectedPostDetail(summedObject);

                    } else {
                        setSelectedPostDetail(posts[selectedIndex])
                    }

                } else if (selectedType == "hashtag") {
                    if (selectedPost == "all") {
                        const uniqueHashtags = new Map();

                        hashtag?.forEach((obj: any) => {
                            if (obj?.hashtag && !uniqueHashtags.has(obj.hashtag)) {
                                uniqueHashtags.set(obj.hashtag, obj);
                            }
                        });

                        const summedObject = Array.from(uniqueHashtags.values()).reduce((acc: any, obj: any) => {
                            Object.keys(obj).forEach((key) => {
                                const value = obj[key];
                                if (typeof value === "number" || value === null) {
                                    acc[key] = (acc[key] || 0) + (value || 0);
                                }
                            });
                            return acc;
                        }, {});

                        setSelectedPostDetail(summedObject);

                    } else {
                        setSelectedPostDetail(hashtag[selectedIndex])
                    }
                }
            }
            if (selectedInsights == "Brand Insights") {
                if (selectedPost == "all") {
                    const summedObject = brandSights?.reduce((acc: any, obj: any) => {
                        Object.keys(obj).forEach((key) => {
                            const value = obj[key];
                            if (typeof value === "number" || value === null) {
                                acc[key] = (acc[key] || 0) + (value || 0);
                            }
                        });
                        return acc;
                    }, {});
                    setSelectedPostDetail(summedObject)
                } else {
                    setSelectedPostDetail(brandSights[selectedIndex])
                }
            }
            const getTypeColor = (type: string) => {
                switch (type) {
                    case "campaign-period":
                        return "#20c997";
                    case "post-campaign-period":
                        return "#0dcaf0";
                    case "pre-campaign-period":
                        return "#fd7e14";
                    default:
                        return "rgba(252, 240, 217, 1)";
                }
            };
            let postdates: any = []
            let postcol: any = []
            let postlikes: any = []
            let postComm: any = []
            let postengagement: any = []
            let postmedia: any = []
            let postshare: any = []
            let postview: any = []
            let postBasicEngg: any = []
            let postImpressEngg: any = []
            let postWeightEngg: any = []
            let postssData: any = []

            let hashtagdates: any = []
            let hashtagcol: any = []
            let hashtaglikes: any = []
            let hashtagengagement: any = []
            let hashtagmediaa: any = []
            let hashtagshare: any = []
            let hashtagComm: any = []
            let hashtagview: any = []
            let hashtagBasicEngg: any = []
            let hashtagImpressEngg: any = []
            let hashtagWeightEngg: any = []

            let branddates: any = []
            let brandcol: any = []
            let brandlikes: any = []
            let brandfollowers: any = []
            let brandfollowing: any = []
            let brandengagement: any = []
            let brandbasicEng: any = []
            let brandimpressEng: any = []
            let brandWeightEng: any = []
            let brandPostcount: any = []

            let avgdates: any = []
            let avglikes: any = []
            let avgfollowers: any = []
            let avgshares: any = []
            let avgfollowing: any = []
            let avgviews: any = []
            let avgcomment: any = []

            let allPostDat: any = []
            let allHashDat: any = []
            let allBrandInsightDat: any = []

            data?.data?.map((item: any, index: any) => {
                item?.insights?.campaignInsights.map((item: any, index: any) => {
                    // if (item?.campaign_type == "post" && item?.post_id == selectedPostDetail?.post_id) {
                    if (item?.campaign_type === "post" && (selectedPost === "all" || (item?.post_id === selectedPostDetail?.post_id))) {
                        allPostDat.push(item)
                        postdates.push(item.date || 0)
                        postcol.push(item.type)
                        postssData.push({
                            label: item.date,
                            y: item.likes_count,
                            color: getTypeColor(item.type)
                        })
                        postlikes.push(item.likes_count || 0)
                        postComm.push(item.comments_count || 0)
                        postengagement.push(item.engagement_score || 0)
                        postview.push(item.views_count || 0)
                        postmedia.push(item.media_count || 0)
                        postshare.push(item.shares_count || 0)
                        postBasicEngg.push(item.basicEngagementRate || 0)
                        postImpressEngg.push(item.impressionEngagementRate || 0)
                        postWeightEngg.push(item.weightedEngagementRate || 0)

                    }
                })
            })
            setAllPostsData(allPostDat)
            setPostsData(postssData)
            setPostDates(postdates);
            setPostColors(postcol)
            setPostComments(postComm)
            setPostlikes(postlikes);
            setPostShares(postshare)
            setPostEngagement(postengagement)
            setPostMedia(postmedia)
            setPostView(postview)
            setPostbasicEng(postBasicEngg)
            setPostimpressEng(postImpressEngg)
            setPostWeightEng(postWeightEngg)

            data?.data?.map((item: any, index: any) => {
                item?.insights?.campaignInsights.map((item: any, index: any) => {
                    // if (item?.campaign_type == "hashtag" && item?.hashtag == selectedPostDetail?.hashtag) {
                    if (item?.campaign_type === "hashtag" && (selectedPost === "all" || (item?.hashtag === selectedPostDetail?.hashtag))) {
                        hashtagdates.push(item.date || 0)
                        allHashDat.push(item)
                        hashtagcol.push(item.type)
                        hashtaglikes.push(item.likes_count || 0)
                        hashtagComm.push(item.comments_count || 0)
                        hashtagengagement.push(item.engagement_score || 0)
                        hashtagmediaa.push(item.media_count || 0)
                        hashtagshare.push(item.shares_count || 0)
                        hashtagview.push(item.view_count || 0)
                        hashtagBasicEngg.push(item.basicEngagementRate || 0)
                        hashtagImpressEngg.push(item.impressionEngagementRate || 0)
                        hashtagWeightEngg.push(item.weightedEngagementRate || 0)
                    }
                })
            })
            setAllHashtagsData(allHashDat)
            setHashtagDates(hashtagdates);
            setHashtagColors(hashtagcol)
            setHashtagComments(hashtagComm)
            setHashtaglikes(hashtaglikes);
            setHashtagShares(hashtagshare)
            setHashtagEngagement(hashtagengagement)
            setHashtagMedia(hashtagmediaa)
            setHashtagView(hashtagview)
            setHashtagbasicEng(postBasicEngg)
            setHashtagimpressEng(postImpressEngg)
            setHashtagWeightEng(postWeightEngg)
            data?.data?.map((item: any, index: any) => {
                item?.insights?.brandInsights.map((item: any, index: any) => {
                    // if (item?.acc_id == selectedPostDetail?.acc_id) {
                    if (selectedPost === "all" || (item?.acc_id === selectedPostDetail?.acc_id)) {
                        branddates.push(item.date || 0)
                        allBrandInsightDat.push(item)
                        brandcol.push(item.type)
                        brandlikes.push(item.likes_count || 0)
                        brandengagement.push(item.engagement_score || 0)
                        brandfollowers.push(item.followers_count || 0)
                        brandfollowing.push(item.following_count || 0)
                        brandbasicEng.push(item.basicEngagementRate || 0)
                        brandimpressEng.push(item.impressionEngagementRate || 0)
                        brandWeightEng.push(item.weightedEngagementRate || 0)
                        brandPostcount.push(item.posts_count || 0)
                        avgdates.push(item.date || 0)
                        avglikes.push(item.avg_likes_count || 0)
                        avgfollowers.push(item.followers_count || 0)
                        avgfollowing.push(item.following_count || 0)
                        avgcomment.push(item.avg_comments_count || 0)
                        avgviews.push(item.avg_views_count || 0)
                        avgshares.push(item.avg_shares_count || 0)
                    }
                })
            })
            setAllBrandSightsData(allBrandInsightDat)
            setBrandDates(avgdates);
            setBrandColors(brandcol)
            setBrandComments(avgcomment)
            setBrandlikes(avglikes);
            setBrandShares(avgshares)
            setBrandPosts(brandPostcount)
            setBrandView(avgviews)
            setBrandFollowers(avgfollowers)
            setBrandFollowing(avgfollowing)
            setBrandBasicEngagementRate(brandbasicEng)
            setBrandimpressionEngagementRate(brandimpressEng)
            setBrandweightedEngagementRate(brandWeightEng)
            setLoader(false)
        }

    }, [data, selectedPostDetail, selectedPost, selectDate])

    const divstyle: any = {
        margin: 0, display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "flex-start",
        width: deviceType == "mobile" ? "100%" : '32%',
        borderRadius: "5px",
        borderWidth: "0.8px",
        padding: "10px",
        border: "0.8px solid #979797",
        height: "120px",
        cursor: 'pointer',
    };

    const spanStyle: any = {
        fontFamily: "Inter",
        fontWeight: 800,
        fontSize: "17px",
        lineHeight: "30px",
        letterSpacing: "0%",
        textAlign: "center",
    }

    const span1Style: any = {
        fontFamily: "Inter",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "30px",
        letterSpacing: "0%",
        textAlign: "center",
    }

    const selectionstyle: any = {
        fontFamily: "Inter",
        width: 'auto',
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "25px",
        letterSpacing: "0%",
        textAlign: "left",
        borderRadius: "10px",
        padding: "0px 10px",
        cursor: "pointer",
        display: "inline-block",
        marginRight: "4px",
    }

    // const brandposts: any = [...Array.from({ length: allPosts?.length }, (_, i) => `Post ${i + 1}`)];
    // const tagposts: any = [...Array.from({ length: allHashtags?.length }, (_, i) => `Post ${i + 1}`)];
    const brandposts: any = Array.from(new Set(allPosts?.map((post: any) => post.post_id))).map((post_id, i) => `Post ${i + 1}`);
    const tagposts: any = Array.from(new Set(allHashtags?.map((post: any) => post.post_id))).map((post_id, i) => `Post ${i + 1}`);

    let brandInsightsposts: any = []
    // allbrandInsights?.map((item: any, index: any) => {
    //     campaignData.asset_acc_id == item.acc_id ?
    //         (brandInsightsposts.push((campaignData.asset_acc?.toUpperCase()))) : (
    //             brandInsightsposts.push(campaignData.brand_acc?.toUpperCase())
    //         )
    // })
    allbrandInsights?.forEach((item: any) => {
        const valueToPush = campaignData.asset_acc_id === item.acc_id
            ? campaignData.asset_acc?.toUpperCase()
            : campaignData.brand_acc?.toUpperCase();

        if (valueToPush && !brandInsightsposts.includes(valueToPush)) {
            brandInsightsposts.push(valueToPush);
        }
    });


    if (!loader) {
        return (
            <Grid item xs={12} md={12} lg={12} sx={{ backgroundColor: 'rgb(250,250,250)', height: '90vh', overflowY: "scroll", overflowX: 'hidden' }}>
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={5000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleCloseSnackbar}
                        severity={snackbar.severity as AlertColor}
                    >
                        {snackbar.message}
                    </MuiAlert>
                </Snackbar>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        alignItems: 'center',
                        padding: '5px',
                        backgroundColor: 'rgb(250,250,250)',
                        height: '90vh',
                        overflowX: 'hidden',
                        overflowY: 'scroll'
                    }}
                >
                    <Grid xs={12} md={12} lg={12} width={"98%"} spacing={2} marginTop={"10px"}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                backgroundColor: '#FFF',
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                padding: "15px",
                                alignItems: 'center',
                            }}
                        >
                            <Breadcrumb
                                linkDetails={linkDetails}
                                underline="always"
                                maxItems={3}
                                itemBeforeCollapse={1}
                                itemAfterCollapse={1}
                                iconName="arrow_forward_ios_black_24dp"
                                iconSize={20}
                                iconLabel="Breadcrumb-Arrow-Right"
                                iconStyle="regular"
                                color="#333"
                                textColor="#333"
                            />
                        </Box>
                    </Grid>
                    <div style={{ width: '98%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: "#FFF", padding: 20 }}>
                        <div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', borderStyle: 'solid', borderWidth: '0px', width: '100%', gap: '10px', border: "1px solid #F2F2F2", padding: 20, borderRadius: '16px' }}>
                            <div style={{ display: 'flex', justifyContent: "space-between", width: '100%' }}>
                                <ZoptsuUnderlineTitle
                                    fontSizeOnLargeScreen="35px"
                                    fontSizeOnMediumScreen="33px"
                                    fontSizeOnSmallScreen="33px"
                                    fontSizeOnExtraSmallScreen="33px"
                                    titleText={'Campaign Details'}
                                    letterSpacing="1.92px"
                                    lineHeight="40.2px"
                                    textAlign="start"
                                    underlineWidthForDesktop="100%"
                                    underlineWidthForSmallTablet="100%"
                                    underlineWidthForMobile="100%"
                                    underlineBottomForDesktop="18%"
                                    underlineBottomForSmallTablet="21%"
                                    underlineBottomForMobile="24%"
                                    paddingLeft="0px"
                                    underlineHeight="9px"
                                />
                                <button onClick={() => { navigate(`/createreport?id=${id}`); }} style={{
                                    width: "32px", cursor: 'pointer', padding: 0, margin: 0, backgroundColor: "transparent", border: "0px solid #000", display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center"
                                }}>
                                    <img src={EditIconn} alt="Edit Icon" width={32} height={32} />
                                </button>
                            </div>
                            <div style={{ display: 'flex', flexWrap: "wrap", marginTop: '20px', width: "100%" }}>
                                <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                                    <div style={{}}>
                                        <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                                            {campaignData?.campaign_name}
                                        </div>
                                        <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                                            {'Campaign Name'}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                                    <div style={{}}>
                                        <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                                            {campaignData?.brand_acc}
                                        </div>
                                        <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                                            {'Brand Account Id'}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                                    <div style={{}}>
                                        <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                                            {campaignData?.asset_acc}
                                        </div>
                                        <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                                            {'Asset Account Id'}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                                    <div style={{}}>
                                        <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                                            {campaignData?.platform}
                                        </div>
                                        <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                                            {'Platform'}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                                    <div style={{}}>
                                        <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                                            {campaignData?.pre_camp_from?.split("T")?.[0] || "NA"}
                                        </div>
                                        <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                                            {'Pre Campaign From'}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                                    <div style={{}}>
                                        <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                                            {campaignData?.pre_camp_to?.split("T")?.[0] || "NA"}
                                        </div>
                                        <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                                            {'Pre Campaign To'}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                                    <div style={{}}>
                                        <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                                            {campaignData?.camp_from?.split("T")?.[0] || "NA"}
                                        </div>
                                        <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                                            {'Campaign From'}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                                    <div style={{}}>
                                        <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                                            {campaignData?.camp_to?.split("T")?.[0] || "NA"}
                                        </div>
                                        <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                                            {'Campaign To'}
                                        </div>
                                    </div>
                                </Col>  <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                                    <div style={{}}>
                                        <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                                            {campaignData?.post_camp_from?.split("T")?.[0] || "NA"}
                                        </div>
                                        <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                                            {'Post Campaign From'}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} md={3} lg={3} style={{ marginBottom: '20px', padding: '0px', paddingTop: '10px', paddingLeft: '15px', }}>
                                    <div style={{}}>
                                        <div style={{ fontSize: '16px', fontWeight: '600', textAlign: 'start', color: '#333333', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
                                            {campaignData?.post_camp_to?.split("T")?.[0] || "NA"}
                                        </div>
                                        <div style={{ fontSize: '14px', fontWeight: '500', textAlign: 'start', color: '#4F4F4F', paddingTop: '5px', paddingRight: '10px' }}>
                                            {'Post Campaign To'}
                                        </div>
                                    </div>
                                </Col>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: deviceType == "mobile" ? "column" : 'row', justifyContent: 'flex-start', alignItems: 'center', gap: '20px', padding: '5px', marginBottom: '10px', backgroundColor: '#FFF', borderTop: "1px solid #E0E0E0" }}>
                                <div style={{ width: deviceType == "mobile" ? "100%" : '70%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#FFF', gap: '15px', marginTop: '20px' }}>
                                    {["Brand Insights", "Campaign Insights"].map((item: any, index: any) => (
                                        <span
                                            key={index}
                                            onClick={() => {
                                                setSelectedInsights(item)
                                                setSelectedIndex(0)
                                                setSelectedPost("all")
                                                // if (item == "Campaign Insights") {
                                                //     setSelectedPostDetail(allPosts[0])
                                                //     setSelectedPost(brandposts[0])
                                                //     setSelectedType("post")
                                                // } else if (item == "Brand Insights") {
                                                //     setSelectedPostDetail(allbrandInsights[0])
                                                //     setSelectedPost(brandInsightsposts[0])
                                                // }
                                            }}
                                            style={{
                                                fontFamily: "BebasNeue",
                                                fontWeight: 400,
                                                fontSize: deviceType == "mobile" ? "22px" : "28px",
                                                lineHeight: "42px",
                                                letterSpacing: "0%",
                                                textDecoration: item == selectedInsights ? "underline" : "none",
                                                color: item == selectedInsights ? "#E20B18" : "#484949",
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {item}
                                        </span>
                                    ))}
                                </div>
                                <div style={{ width: deviceType == "mobile" ? "100%" : '30%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', backgroundColor: '#FFF', }}>
                                    {/* <input
                                        style={{
                                            width: deviceType == "mobile" ? "100%" : '150px',
                                            padding: '10px',
                                            fontFamily: 'Inter',
                                            fontSize: '15px',
                                            height: "40px",
                                            border: "1px solid rgba(0,0,0,0.5)",
                                            borderRadius: "5px"
                                        }}
                                        type="date"
                                        id="date"
                                        value={selectDate}
                                        onChange={handleDateChange}
                                        min={minDate}
                                        max={maxDate}
                                    /> */}
                                </div>
                            </div>
                            {(selectedInsights == "Campaign Insights") ? (
                                <div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', borderStyle: 'solid', borderWidth: '0px', width: '100%', gap: '20px', borderRadius: '16px' }}>

                                    <div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: "space-between", borderStyle: 'solid', width: '100%', gap: '20px', borderBottom: "1px solid #E0E0E0", paddingBottom: '10px' }}>
                                        <div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: "flex-start", borderStyle: 'solid', width: '50%', gap: '20px', }}>
                                            {[{ label: "Posts", value: "post" }, { label: "Hashtags", value: "hashtag" }].map((item: any, index: any) => (
                                                <span key={index} onClick={() => {
                                                    setSelectedIndex(0)
                                                    setSelectedPost("all")
                                                    setSelectedType(item?.value)
                                                    // if (item?.value == "post") {
                                                    //     setSelectedPostDetail(allPosts[0])
                                                    //     setSelectedPost(brandposts[0])
                                                    // }
                                                    // if (item?.value == "hashtag") {
                                                    //     setSelectedPostDetail(allHashtags[0])
                                                    //     setSelectedPost(tagposts[0])
                                                    // }

                                                }} style={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 600,
                                                    fontSize: "14px",
                                                    lineHeight: "30px",
                                                    letterSpacing: "0%",
                                                    textAlign: "left",
                                                    cursor: 'pointer',
                                                    color: selectedType == item?.value ? "#E20B18" : '#828282',
                                                    borderBottom: selectedType == item?.value ? "2px solid #E20B18" : ""
                                                }}>
                                                    {item?.label}
                                                </span>
                                            ))}
                                        </div>
                                    </div>

                                    {(selectedType == "post") && (
                                        <div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: "space-between", borderStyle: 'solid', width: '100%', gap: '20px', overflowX: "scroll", scrollbarWidth: "none" }}>
                                            <div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: "flex-start", borderStyle: 'solid', width: '80%', gap: '20px', overflowX: "scroll", scrollbarWidth: "none" }}>
                                                <span
                                                    onClick={() => {
                                                        setSelectedPost("all");
                                                        // setSelectedPostDetail(allPosts[index])
                                                        // setSelectedIndex(index)
                                                    }}
                                                    style={{
                                                        ...selectionstyle,
                                                        color: selectedPost === "all" ? "#E20B18" : "#828282",
                                                        border: selectedPost === "all" ? "1px solid #E20B18" : "1px solid #828282",
                                                    }}
                                                >
                                                    {"All"}
                                                </span>
                                                {
                                                    brandposts
                                                        // data?.campaign.posts
                                                        .map((item: any, index: any) => (
                                                            <span
                                                                key={index}
                                                                onClick={() => {
                                                                    setSelectedPost(item);
                                                                    setSelectedPostDetail(allPosts[index])
                                                                    setSelectedIndex(index)
                                                                }}
                                                                style={{
                                                                    ...selectionstyle,
                                                                    color: selectedPost === item ? "#E20B18" : "#828282",
                                                                    border: selectedPost === item ? "1px solid #E20B18" : "1px solid #828282",
                                                                }}
                                                            >
                                                                {item}
                                                            </span>
                                                        ))}
                                            </div>
                                            <input
                                                style={{
                                                    width: deviceType == "mobile" ? "100%" : '150px',
                                                    padding: '10px',
                                                    fontFamily: 'Inter',
                                                    fontSize: '15px',
                                                    height: "40px",
                                                    border: "1px solid rgba(0,0,0,0.5)",
                                                    borderRadius: "5px"
                                                }}
                                                type="date"
                                                id="date"
                                                value={selectDate}
                                                onChange={handleDateChange}
                                                min={minDate}
                                                max={maxDate}
                                            />

                                        </div>)}
                                    {(selectedType == "hashtag") && (<div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: "space-between", borderStyle: 'solid', width: '100%', gap: '20px', overflowX: "scroll", scrollbarWidth: "none" }}>
                                        <div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: "flex-start", borderStyle: 'solid', width: '80%', gap: '20px', overflowX: "scroll", scrollbarWidth: "none" }}>
                                            <span
                                                onClick={() => {
                                                    setSelectedPost("all");
                                                    // setSelectedPostDetail(allPosts[index])
                                                    // setSelectedIndex(index)
                                                }}
                                                style={{
                                                    ...selectionstyle,
                                                    color: selectedPost === "all" ? "#E20B18" : "#828282",
                                                    border: selectedPost === "all" ? "1px solid #E20B18" : "1px solid #828282",
                                                }}
                                            >
                                                {"All"}
                                            </span>
                                            {
                                                tagposts
                                                    .map((item: any, index: any) => (
                                                        <span
                                                            key={index}
                                                            onClick={() => {
                                                                setSelectedPost(item);
                                                                setSelectedPostDetail(allHashtags[index])
                                                                setSelectedIndex(index)
                                                            }}
                                                            style={{
                                                                ...selectionstyle,
                                                                color: selectedPost === item ? "#E20B18" : "#828282",
                                                                border: selectedPost === item ? "1px solid #E20B18" : "1px solid #828282",
                                                            }}
                                                        >
                                                            {allHashtags[index]?.hashtag}
                                                        </span>
                                                    ))}
                                        </div>
                                        <input
                                            style={{
                                                width: deviceType == "mobile" ? "100%" : '150px',
                                                padding: '10px',
                                                fontFamily: 'Inter',
                                                fontSize: '15px',
                                                height: "40px",
                                                border: "1px solid rgba(0,0,0,0.5)",
                                                borderRadius: "5px"
                                            }}
                                            type="date"
                                            id="date"
                                            value={selectDate}
                                            onChange={handleDateChange}
                                            min={minDate}
                                            max={maxDate}
                                        />
                                    </div>)}
                                    {(selectedType == "post") && (<div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: "flex-start", borderStyle: 'solid', width: '100%', gap: '10px', flexWrap: "wrap" }}>
                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("PILC");
                                            }}
                                            style={{ ...divstyle }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", }}>

                                            </div>
                                            <img src={favoriteLike} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.likes_count || "NA"}</span>
                                            <span style={span1Style}> Likes</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("PISC");
                                            }}
                                            style={{ ...divstyle }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", }}>
                                                {/* <ZupotsuTooltip
                                                    tooltipMessage={""}
                                                    icon={infoCircle}
                                                /> */}
                                            </div>
                                            <img src={share} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.shares_count || "NA"}</span>
                                            <span style={span1Style}> Shares</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("PICE");
                                            }}
                                            style={divstyle}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", }}>
                                                {/* <ZupotsuTooltip
                                                    tooltipMessage={""}
                                                    icon={infoCircle}
                                                /> */}
                                            </div>
                                            <img src={chat} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.comments_count || 0}</span>
                                            <span style={span1Style}> Comments</span>
                                        </div>

                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("PITV");
                                            }}
                                            style={{ ...divstyle }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", marginBottom: "-15px" }}>
                                                <ZupotsuTooltip
                                                    tooltipMessage={"The overall number of times a video has been played, including repeat views by the same user"}
                                                    icon={infoCircle}
                                                />
                                            </div>
                                            <img src={visibility} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.views_count || "NA"}</span>
                                            <span style={span1Style}>Total Views</span>
                                        </div>
                                        <div onClick={() => {
                                            setOpenChartPopup(true);
                                            setChartName("PIIE");
                                        }} style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", marginBottom: "-15px" }}>
                                                <ZupotsuTooltip
                                                    tooltipMessage={"Impressions engagement rate refers to the percentage of users who engaged with a single post during its lifetime, divided by its number of impressions"}
                                                    icon={infoCircle}
                                                />
                                            </div>
                                            <img src={engagementrate} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.impressionEngagementRate ? `${selectedPostDetail?.impressionEngagementRate?.toFixed(2)}%` : "NA"}</span>
                                            <span style={span1Style}>Impression Engagement Rate</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("PIBE");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", marginBottom: "-15px" }}>
                                                <ZupotsuTooltip
                                                    tooltipMessage={"The engagement rate is calculated as the total number of interactions your content receives divided by your total number of followers, multiplied by 100%"}
                                                    icon={infoCircle}
                                                />
                                            </div>
                                            <img src={engagementrate} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.basicEngagementRate ? `${selectedPostDetail?.basicEngagementRate?.toFixed(2)}%` : "NA"}</span>
                                            <span style={span1Style}>Basic Engagement Rate</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("PIWE");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", marginBottom: "-15px" }}>
                                                <ZupotsuTooltip
                                                    tooltipMessage={"A weighted engagement rate assigns different values to various interactions (likes, comments, shares, etc.) to provide a more nuanced understanding of audience engagement, rather than simply counting each interaction equally"}
                                                    icon={infoCircle}
                                                />
                                            </div>
                                            <img src={engagementrate} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.weightedEngagementRate ? `${selectedPostDetail?.weightedEngagementRate?.toFixed(2)}%` : "NA"}</span>
                                            <span style={span1Style}>Weighted Engagement Rate</span>
                                        </div>





                                    </div>)}
                                    {(selectedType == "hashtag") && (<div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: "flex-start", borderStyle: 'solid', width: '100%', gap: '10px', flexWrap: "wrap" }}>
                                        {/* <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("HIIE");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <img src={engagementrate} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.impressionEngagementRate ? `${selectedPostDetail?.impressionEngagementRate?.toFixed(2)}%` : "NA"}</span>
                                            <span style={span1Style}>Impression Engagement Rate</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("HIBE");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <img src={engagementrate} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.basicEngagementRate ? `${selectedPostDetail?.basicEngagementRate?.toFixed(2)}%` : "NA"}</span>
                                            <span style={span1Style}>Basic Engagement Rate</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("HIWE");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <img src={engagementrate} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.weightedEngagementRate ? `${selectedPostDetail?.weightedEngagementRate?.toFixed(2)}%` : "NA"}</span>
                                            <span style={span1Style}>Weighted Engagement Rate</span>
                                        </div> */}

                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("HIMC");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", }}>
                                                {/* <ZupotsuTooltip
                                                    tooltipMessage={""}
                                                    icon={infoCircle}
                                                /> */}
                                            </div>
                                            <img src={visibility} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.media_count || "NA"}</span>
                                            <span style={span1Style}>Media</span>
                                        </div>


                                    </div>)}
                                </div>
                            ) : (
                                <div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', borderStyle: 'solid', borderWidth: '0px', width: '100%', gap: '20px', borderRadius: '16px' }}>

                                    <div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: "space-between", borderStyle: 'solid', width: '100%', gap: '20px', }}>

                                        <div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: "space-between", borderStyle: 'solid', width: '100%', gap: '20px', overflowX: "scroll", scrollbarWidth: "none" }}>
                                            <div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: "flex-start", borderStyle: 'solid', width: '80%', gap: '20px', overflowX: "scroll", scrollbarWidth: "none" }}>
                                                <span
                                                    onClick={() => {
                                                        setSelectedPost("all");
                                                        // setSelectedPostDetail(allPosts[index])
                                                        // setSelectedIndex(index)
                                                    }}
                                                    style={{
                                                        ...selectionstyle,
                                                        color: selectedPost === "all" ? "#E20B18" : "#828282",
                                                        border: selectedPost === "all" ? "1px solid #E20B18" : "1px solid #828282",
                                                    }}
                                                >
                                                    {"All"}
                                                </span>
                                                {brandInsightsposts.map((item: any, index: any) => (
                                                    <div
                                                        key={index}
                                                        onClick={() => {
                                                            setSelectedPost(item);
                                                            setSelectedPostDetail(allbrandInsights[index])
                                                            setSelectedIndex(index)
                                                        }}
                                                        style={{
                                                            ...selectionstyle,
                                                            color: selectedPost === item ? "#E20B18" : "#828282",
                                                            border: selectedPost === item ? "1px solid #E20B18" : "1px solid #828282",
                                                        }}
                                                    >
                                                        {item}
                                                    </div>
                                                ))}

                                            </div>
                                            <input
                                                style={{
                                                    width: deviceType == "mobile" ? "100%" : '150px',
                                                    padding: '10px',
                                                    fontFamily: 'Inter',
                                                    fontSize: '15px',
                                                    height: "40px",
                                                    border: "1px solid rgba(0,0,0,0.5)",
                                                    borderRadius: "5px"
                                                }}
                                                type="date"
                                                id="date"
                                                value={selectDate}
                                                onChange={handleDateChange}
                                                min={minDate}
                                                max={maxDate}
                                            />
                                        </div>

                                    </div>
                                    <div style={{ borderColor: '#FFF', display: 'flex', flexDirection: 'row', alignItems: "flex-start", justifyContent: "flex-start", borderStyle: 'solid', width: '100%', gap: '10px', flexWrap: "wrap" }}>

                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("BIALC");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", }}>
                                                {/* <ZupotsuTooltip
                                                    tooltipMessage={""}
                                                    icon={infoCircle}
                                                /> */}
                                            </div>
                                            <img src={favoriteLike} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.avg_likes_count || "NA"}</span>
                                            <span style={span1Style}>Average Likes</span>
                                        </div>

                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("BIACC");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", }}>
                                                {/* <ZupotsuTooltip
                                                    tooltipMessage={""}
                                                    icon={infoCircle}
                                                /> */}
                                            </div>
                                            <img src={chat} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.avg_comments_count || "NA"}</span>
                                            <span style={span1Style}>Average Comments</span>
                                        </div>

                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("BIASC");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", }}>
                                                {/* <ZupotsuTooltip
                                                    tooltipMessage={""}
                                                    icon={infoCircle}
                                                /> */}
                                            </div>
                                            <img src={share} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.avg_shares_count ? selectedPostDetail?.avg_shares_count : "NA"}</span>
                                            <span style={span1Style}>Average Shares</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("BIAVC");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", }}>
                                                {/* <ZupotsuTooltip
                                                    tooltipMessage={""}
                                                    icon={infoCircle}
                                                /> */}
                                            </div>
                                            <img src={visibility} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.avg_views_count || "NA"}</span>
                                            <span style={span1Style}>Average Views</span>
                                        </div>

                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("BIPC");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", }}>
                                                {/* <ZupotsuTooltip
                                                    tooltipMessage={""}
                                                    icon={infoCircle}
                                                /> */}
                                            </div>
                                            <img src={folloers} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.posts_count || "NA"}</span>
                                            <span style={span1Style}>Posts</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("BIFC");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", }}>
                                                {/* <ZupotsuTooltip
                                                    tooltipMessage={""}
                                                    icon={infoCircle}
                                                /> */}
                                            </div>
                                            <img src={folloers} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.followers_count || "NA"}</span>
                                            <span style={span1Style}>Followers</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("BIFGC");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", }}>
                                                {/* <ZupotsuTooltip
                                                    tooltipMessage={""}
                                                    icon={infoCircle}
                                                /> */}
                                            </div>
                                            <img src={folloers} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.following_count || "NA"}</span>
                                            <span style={span1Style}>Following</span>
                                        </div>

                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("BIBER");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", marginBottom: "-15px" }}>
                                                <ZupotsuTooltip
                                                    tooltipMessage={"The engagement rate is calculated as the total number of interactions your content receives divided by your total number of followers, multiplied by 100%"}
                                                    icon={infoCircle}
                                                />
                                            </div>
                                            <img src={engagementrate} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.basicEngagementRate ? `${selectedPostDetail?.basicEngagementRate?.toFixed(2)}%` : "NA"}</span>
                                            <span style={span1Style}>Basic Engagement Rate</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("BIWER");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", marginBottom: "-15px" }}>
                                                <ZupotsuTooltip
                                                    tooltipMessage={"A weighted engagement rate assigns different values to various interactions (likes, comments, shares, etc.) to provide a more nuanced understanding of audience engagement, rather than simply counting each interaction equally"}
                                                    icon={infoCircle}
                                                />
                                            </div>
                                            <img src={engagementrate} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.weightedEngagementRate ? `${selectedPostDetail?.weightedEngagementRate?.toFixed(2)}%` : "NA"}</span>
                                            <span style={span1Style}>Weighted Engagement Rate</span>
                                        </div>
                                        <div
                                            onClick={() => {
                                                setOpenChartPopup(true);
                                                setChartName("BIIER");
                                            }}
                                            style={{ ...divstyle, }}>
                                            <div style={{ width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "flex-end", marginBottom: "-15px" }}>
                                                <ZupotsuTooltip
                                                    tooltipMessage={"Impressions engagement rate refers to the percentage of users who engaged with a single post during its lifetime, divided by its number of impressions"}
                                                    icon={infoCircle}
                                                />
                                            </div>
                                            <img src={engagementrate} style={{
                                                width: 25.5,
                                                height: 28.5,
                                            }} />
                                            <span style={spanStyle}>{selectedPostDetail?.impressionEngagementRate ? `${selectedPostDetail?.impressionEngagementRate?.toFixed(2)}%` : "NA"}</span>
                                            <span style={span1Style}>Impression Engagement Rate</span>
                                        </div>





                                    </div>
                                </div>)}
                        </div>
                    </div>



                    {(selectedInsights == "Campaign Insights") && (
                        <CampaignInsights
                            selectedPostDetail={selectedPostDetail}
                            postdates={postDatess}
                            postColors={postColors}
                            postlikes={postLikess}
                            postComments={postComments}
                            postShares={postShares}
                            postengagement={postEngagement}
                            postmedia={postMedia}
                            postshare={postShares}
                            postView={postView}
                            postbasicEng={postbasicEng}
                            postimpressEng={postimpressEng}
                            postweightEng={postweightEng}
                            postSData={postSData}

                            selectedType={selectedType}
                            hashtagDates={hashtagDates}
                            hashtagColors={hashtagColors}
                            hashtagLikess={hashtagLikess}
                            hashtagComments={hashtagComments}
                            hashtagShares={hashtagShares}
                            hashtagMedia={hashtagMedia}
                            hashtagView={hashtagView}
                            hashtagEngagement={hashtagEngagement}
                            hashtagbasicEng={hashtagbasicEng}
                            hashtagimpressEng={hashtagimpressEng}
                            hashtagweightEng={hashtagweightEng}
                            minDat={minDat}
                            maxDat={maxDat}

                            allPostsData={allPostsData}
                            allHashtagsData={allHashtagsData}

                            openChartPopup={openChartPopup}
                            setOpenChartPopup={setOpenChartPopup}
                            chartname={chartname}
                        />
                    )}

                    {(selectedInsights == "Brand Insights") && (
                        <BrandInsights
                            selectedPostDetail={selectedPostDetail}
                            brandDates={brandDates}
                            brandColors={brandColors}
                            brandLikess={brandLikess}
                            brandComments={brandComments}
                            brandShares={brandShares}
                            brandPosts={brandPosts}
                            brandView={brandView}
                            brandFollowers={brandFollowers}
                            brandFollowing={brandFollowing}
                            brandbasicEngagementRate={brandbasicEngagementRate}
                            brandimpressionEngagementRate={brandimpressionEngagementRate}
                            brandweightedEngagementRate={brandweightedEngagementRate}
                            openChartPopup={openChartPopup}
                            setOpenChartPopup={setOpenChartPopup}
                            chartname={chartname}
                            allbrandInsightsData={allbrandInsightsData}
                            minDat={minDat}
                            maxDat={maxDat}
                        />
                    )}
                </Box >
                {/* <div
                    style={{
                        marginTop: '5px',
                        padding: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent:
                            deviceType === 'mobile' ? 'center' : 'space-between',
                        flexWrap: 'wrap',
                        gap: deviceType === 'mobile' ? '20px' : '0',
                        width: '100%',
                        border: deviceType === 'mobile' ? "" : "1px solid #ff00000d",
                        boxShadow: deviceType === 'mobile' ? "" : "3px 0px 6px #91919b54",
                        bottom: "0px",
                        right: "0px",
                        zIndex: 5,
                        backgroundColor: '#FFF',
                        paddingLeft: deviceType === ('tablet') ? "4%" : deviceType === 'mobile' ? "20px" : '4%',
                        paddingRight: deviceType === ('tablet') ? "4%" : deviceType === 'mobile' ? "20px" : '4%'

                    }}
                >
                    <ZupotsuButton
                        name={"Cancel"}
                        handleClick={() => {
                            navigate(-1)
                        }}
                        padding={"10px 25px"}
                        leadingIcon={arrowLeft}
                        isCustomColors={true}
                        variant={'outlined'}
                        customTextColor="rgba(189, 189, 189, 1)"
                        customBgColor="#fff"
                        customBgColorOnhover="white"
                        customTextColorOnHover="#828282"
                        customOutlineColor={'1px solid rgba(189, 189, 189, 1)'}
                        customOutlineColorOnHover={'1px solid rgba(189, 189, 189, 1)'}
                    />

                    <ZupotsuButton
                        padding={"10px 25px"}
                        customBgColor={"rgba(226, 11, 24, 1)"}
                        name={"Publish"}
                        disabled={false}
                        customOutlineColor={'0px solid transparent'}
                        handleClick={() => { }}
                    />

                </div> */}
            </Grid >

        )
    }
    else {
        return (
            <div className="centered-container">
                <div className="loader"></div>
            </div>
        )
    }

}

export default Brandanalysis
